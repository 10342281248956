/* Page Setup
================================*/
.vh-section-outer {
  display: table;
  width: 100%;
  height: 30vh;
}

.section-inner {
  display: table-cell;
  vertical-align: middle;
}

/* Tab Setup
===============================*/
.tabSection {
  height: auto;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.tab-content {
  height: auto;
  padding: 40px 30px;
}

/* nav-pills
===============================*/
.nav {
  text-align: center;
}

.nav-pills>li.category-link {
  float: none;
  display: inline-block;
  width: 24%;
  min-height: 45px;
  margin-left: 10px;
}

.nav-pills>li.category-link>a {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 15px;
  background-color: #dadada;
  font-weight: 500;
  text-align: left;
  border-radius: 4px;
  transition: color, background-color 0.3s ease;
  font-size: 18px;
}

.nav-pills>li.category-link.active a {
  color: #000000;
  background-color: #002856;
}

.nav-pills>li.category-link>a:hover {
  text-decoration: none;
  background-color: #f6c93f;
  color: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

a {
  color: #333333;
}

a p {
  margin-bottom: 0px;
}

/* tab-content
===============================*/
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

/* Media Queries
===============================*/

@media (max-width: 768px) {
  .hidden-xs {
    display: none;
  }

  li.visible-xs {
    display: inline-block !important;
    float: none;
  }

  .tab-content {
    height: auto;
    padding: 20px 10px;
  }

  .nav {
    padding: 0px;
    position: absolute;
    bottom: 15px;
    width: 100%;
  }

  .nav-pills>li.category-link>a {
    text-align: center;
    padding: 10px 0px;
    font-size: 16px;
    min-height: 57px;
  }

  .nav-pills>li.category-link {
    float: none;
    display: inline-block;
    width: 45%;
    text-align: center;
    vertical-align: middle;
  }

  .nav-pills>li.active::after {
    display: none;
  }

  .btnPrevious,
  .btnNext {
    background-color: #dadada !important;
    color: #333333;
  }

  .btnNext:hover,
  .btnPrevious:hover {
    background-color: #dadada !important;
    color: #333333 !important;
  }
}