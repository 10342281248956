.question {
    font-size: 16px !important;
    padding: 20px 80px 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.question::after {
    content: "\002B";
    font-size: 2.2rem;
    position: absolute;
    right: 20px;
    transition: 0.2s;
}

.question.active::after {
    transform: rotate(45deg);
}

.answercont {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
}

.answer {
    padding: 0 20px 20px;
    line-height: 30px;
    font-size: 14px !important;
}

.question.active+.answercont {}

@media screen and (max-width: 790px) {
    html {
        font-size: 14px;
    }
}

#faq {
    background-color: #f9f9f9;
    color: black;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.25);
    margin-top: 21px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    #faq {
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        width: 90%;
        margin-right: auto;
    }
}

#liststyle {
    list-style: none;
}

#liststyle li {
    padding: 10px;
    border-bottom: 1px solid #333;
}

#liststyle::before {
    content: "\f0eb";
    font-family: "FontAwesome";
    color: orange;
    float: left;
    font-size: 1.5em;
    margin-right: 15px;
}